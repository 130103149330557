[
  {
    "fixedId": "static_content",
    "name": "Ihre Services mit dem Stadt Wien Konto",
    "items": [
      {
        "id": "0",
        "media": {
          "type": "image",
          "url": "/img/staticContent/repbon.png"
        },
        "provider": "Umwelt",
        "subtitle": "Wien repariert's: Unterstützen Sie den Klimaschutz! Bis zu 50% Förderung für Reparaturen bis 100 Euro ohne Antragsaufwand.",
        "title": "Wiener Reparaturbon"
      },
      {
        "id": "1",
        "media": {
          "type": "image",
          "url": "/img/staticContent/meine-aktivitaeten.png"
        },
        "provider": "Stadt Wien",
        "subtitle": "Einfach den Überblick bewahren: erhalten Sie die neuesten Meldungen über Ihre Anträge, Fristen und Termine.",
        "title": "Meine Aktivitäten"
      },
      {
        "id": "2",
        "media": {
          "type": "image",
          "url": "/img/staticContent/wiener-linien-ubahn.jpg"
        },
        "provider": "Wiener Linien",
        "subtitle": "Erhalten Sie aktuelle Abfahrtszeiten und Störungsmeldungen auf Ihrer Linie.",
        "title": "Meine Öffis"
      },
      {
        "id": "3",
        "media": {
          "type": "image",
          "url": "/img/staticContent/unwetterwarnungen.png"
        },
        "provider": "ZAMG",
        "subtitle": "Bei extremen Wettersituationen wie Sturm, Starkregen oder starkem Glatteis erhalten Sie automatisch eine Warnung.",
        "title": "Unwetterwarnungen"
      },
      {
        "id": "4",
        "media": {
          "type": "image",
          "url": "/img/staticContent/wiener-eistraum-tag.jpg"
        },
        "provider": "Stadt Wien",
        "subtitle": "Sie können aktuelle Veranstaltungen zu Ihren persönlichen Interessen abonnieren.",
        "title": "Meine Veranstaltungen"
      },
      {
        "id": "5",
        "media": {
          "type": "image",
          "url": "/img/staticContent/clubvorteil.jpg"
        },
        "provider": "Stadt Wien",
        "subtitle": "Jetzt beim Vorteilsclub anmelden und bei aktuellen Gewinnspielen teilnehmen.",
        "title": "Vorteilsclub"
      },
      {
        "id": "6",
        "media": {
          "type": "image",
          "url": "/img/staticContent/mein-mieterportal.png"
        },
        "provider": "Wiener Wohnen",
        "subtitle": "Reservieren Sie bei Wiener Wohnen Waschtage, stellen Sie Anträge und verwalten Sie Ihre Profildaten.",
        "title": "Mein Mieterportal"
      }
    ]
  }
]