import React from "react";
import { Modal } from "react-responsive-modal";
import MD from "react-markdown";

import { MapContainer } from "./MapContainer";
import noimage from "../../img/notfound.png";

const basicLink = (link) => /^https?:\/\/.*/i.test(link.url);
const frameLink = (link) => /\/event\/page/i.test(link.url);
const markdown = (link) => link.text && link.type === "markdownLink";
const appLink = (link) => link.type === "appLink" && !basicLink(link);

const hasTitle = (link, title) =>
	!markdown(link) || !link.text || link.text.search(title) < 0;

const getLink = (link) => {
	let url = link.url;

	if (appLink(link) && !agent.mobile) {
		url =
			link.urlAlternateWeb ||
			(agent.macos
				? link.iosStoreCustomLink || "https://www.apple.com/at/app-store/"
				: link.androidStoreLink || "https://play.google.com/store");
	}

	return url;
};

export class FeedCard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			map: null,
			img: props.card.media.url ?? noimage,
			isOpen: false
		};
	}

	modal = (card) => (
		<div id={"modal-" + card.id} className="modal-dialog dash-modal-600">
			<div className="modal-content">
				{hasTitle(card.link, card.title) && (
					<div className="modal-header">
						<h3>{card.title}</h3>
					</div>
				)}
				<div className="modal-body" tabIndex={0}>
					{markdown(card.link) ? (
						<div style={{ maxHeight: "600px", overflowY: "auto" }}>
							<MD
								components={{
									h1: ({ node, ...props }) => (
										<h1
											className="wm-h3 wm-u-mbs"
											{...props}
										/>
									),
									a: ({ node, ...props }) => (
										<a
											className="wm-link--black"
											{...props}
										/>
									)
								}}
								linkTarget="_blank"
							>
								{card.link.text}
							</MD>
						</div>
					) : (
						<iframe
							title={card.title}
							className="markdown-frame"
							id="markdown-frame"
							height="400"
							src={card.link.url}
						></iframe>
					)}
				</div>
			</div>
		</div>
	);

	render = () => {
		const { card } = this.props;
		const { map, img, isOpen } = this.state;

		let mtyp = card.media.type;

		let modalLink = card.link &&  
			(markdown(card.link) || frameLink(card.link));

		return (
			<>
				<li>
					<div className="wm-card js-wm-card" data-wm-card>
						{mtyp === "image" && (
							<div className="wm-card__media">
								<img
									id={"image" + card.id}
									className="wm-image"
									loading="lazy"
									src={img}
									width="350"
									height="197"
									onError={(e) =>
										this.setState({ img: noimage })
									}
									alt={card.title}
								/>
							</div>
						)}
						{mtyp === "map" && (
							<div className="wm-card__media">
								<MapContainer
									id={"map" + card.id}
									style={{ width: "350px", height: "196px" }}
									className="wm-map"
									ref={(ref) => (this.map = ref)}
									mapData={
										map || {
											zoom: 16,
											center: Object.values(
												card.media.location
											)
										}
									}
									onClick={(coords) => console.log(coords)}
									onChange={(mapData) =>
										this.setState({ map: mapData })
									}
								/>
							</div>
						)}

						<span className="wm-badge wm-card__badge">
							{card.provider}{" "}
						</span>

						<h3 className="wm-card__heading ">
							{card.link ? (
								modalLink ? (
									<a
										href="/"
										className="wm-link--unstyled"
										onClick={(e) => {
											e.preventDefault();
											this.setState({ isOpen: true });
										}}
									>
										{card.title}
									</a>
								) : (
									<a
										href={getLink(card.link)}
										className="wm-card__link wm-link--unstyled js-wm-card-link"
									>
										{card.title}
									</a>
								)
							) : (
								card.title
							)}
						</h3>

						<div className="wm-card__content wm-rich-text">
							<p>{card.subtitle}</p>
						</div>

						{mtyp === "markdown" && (
							<div
								className="wm-card__content wm-rich-text"
								style={{ alignSelf: "start" }}
							>
								<MD>{card.media.markdown}</MD>
							</div>
						)}
					</div>
				</li>

				{modalLink && (
					<Modal
						open={isOpen}
						onClose={() => this.setState({ isOpen: false })}
					>
						{this.modal(card)}
					</Modal>
				)}
			</>
		);
	};
}
